<template>
	<div class="webproductapply">
		<div class="webproductapply_mybreadcrumb">
			当前位置：
			<app-mybreadcrumb />
		</div>

		<section class="webproductapply_content">
			<div class="webproductapply_title">产品申请</div>

			<div class="webproductapply_form">
				<el-form :model="formLabelAlign" :rules="rules" ref="formLabelAlign" label-width="150px"
					label-position='top'>
					<el-form-item label="拟融资额度" prop="nums">
						<div class="webproductapply_inp">
							<el-input v-model.number="formLabelAlign.nums" placeholder='请输入拟融资额度'></el-input>
						</div>
						<span class="webproductapply_font">万元</span>
					</el-form-item>

					<el-form-item label="融资期限（月）" prop="month">
						<div class="webproductapply_inp">
							<el-input v-model.number="formLabelAlign.month" placeholder='请输入数字'></el-input>
						</div>
						<span class="webproductapply_font">月</span>
					</el-form-item>

					<el-form-item label="公司名称" prop="comname">
						<div class="webproductapply_inp">
							<el-input v-model="formLabelAlign.comname" placeholder='请输入公司名称'></el-input>
						</div>

					</el-form-item>

					<el-form-item label="所在地区" prop="address">
						<div class="webproductapply_inp">
							<el-select v-model="formLabelAlign.address" placeholder="请选择" clearable @clear='clearClass'>
								<el-option v-for="item in options" :value="item.name"
									@click.native='setAddressId(item.id)'>
								</el-option>
							</el-select>
						</div>
					</el-form-item>


				
					<!-- <el-form-item label="项目准入立项书（含总行业务条线授信准入审查资料）" prop="uploadImg1">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList1"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove1"
								:http-request="httpreq1">

								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>

							<el-progress v-show="imgFlag1 == true" :percentage="percent1"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="项目准入立项书（含总行业务条线授信准入审查资料）" prop="uploadImg1">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList1"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove1"
								:http-request="httpreq1">

								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>

							<el-progress v-show="imgFlag1 == true" :percentage="percent1"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="调查报告（含主页、协办、机构负责人意见）" prop="uploadImg2">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList2"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove2"
								:http-request="httpreq2">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag2 == true" :percentage="percent2"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="现场调查照片（小企业、新客户必须提供）" prop="uploadImg3">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList3"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove3"
								:http-request="httpreq3">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag3 == true" :percentage="percent3"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="经济利润测算系统收益测算报告" prop="uploadImg4">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList4"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove4"
								:http-request="httpreq4">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag4 == true" :percentage="percent4"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="人行征信查询报告" prop="uploadImg5">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList5"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove5"
								:http-request="httpreq5">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag5 == true" :percentage="percent5"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="其他授信申请相关的支持材料（上年度授信批复、贷后检查报告等）">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList6"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove6"
								:http-request="httpreq6">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag6 == true" :percentage="percent6"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="授信业务申请书" prop="uploadImg7">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList7"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove7"
								:http-request="httpreq7">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag7 == true" :percentage="percent7"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="经年检的有效营业执照、副本" prop="uploadImg8">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList8"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove8"
								:http-request="httpreq8">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag8 == true" :percentage="percent8"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="经年检的有效组织机构代码证（如三证合一则不需要）" prop="uploadImg9">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList9"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove9"
								:http-request="httpreq9">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag9 == true" :percentage="percent9"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="税务登记证（如三证合一则不需要）" prop="uploadImg10">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList10"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove10"
								:http-request="httpreq10">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag10 == true" :percentage="percent10"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="工商注册验货报告">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList11"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove11"
								:http-request="httpreq11">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag11 == true" :percentage="percent11"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="经工商登记的最新公司章程" prop="uploadImg12">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList12"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove12"
								:http-request="httpreq12">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag12 == true" :percentage="percent12"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="董事会/股东会决议（根据章程规定是否需要）" prop="uploadImg13">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList13"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove13"
								:http-request="httpreq13">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag13 == true" :percentage="percent13"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="法定代表人证明书及身份证、被授权人身份证" prop="uploadImg14">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList14"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove14"
								:http-request="httpreq14">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag14 == true" :percentage="percent14"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="有权签字人签章样本" prop="uploadImg15">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList15"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove15"
								:http-request="httpreq15">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag15 == true" :percentage="percent15"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="授权委托书" prop="uploadImg16">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList16"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove16"
								:http-request="httpreq16">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag16 == true" :percentage="percent16"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="特殊行业资质证明（如金融同业、建筑施工、房地产企业等）">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList17"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove17"
								:http-request="httpreq17">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag17 == true" :percentage="percent17"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="近连续三年经审计的合并（本部）报表" prop="uploadImg18">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList18"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove18"
								:http-request="httpreq18">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag18 == true" :percentage="percent18"
								class="webproductapply_percentage"></el-progress>
								
						</div>
					</el-form-item>
					
					<el-form-item label="最近三个月中一期的财务报表（合并、本部）" prop="uploadImg19">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList19"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove19"
								:http-request="httpreq19">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag19 == true" :percentage="percent19"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="重要会计科目明细" prop="uploadImg20">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList20"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove20"
								:http-request="httpreq20">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag20 == true" :percentage="percent20"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item>
					
					<el-form-item label="根据监管部门和我行具体业务操作规程，提供交易合同等必须具备的资料" prop="uploadImg21">
						<div class="webproductapply_form_document">
							<el-upload ref='uploadfilesdocument' :file-list="documentList21"
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="handleRemove21"
								:http-request="httpreq21">
					
								<el-button size="small" type="primary">选择文件</el-button>
							</el-upload>
					
							<el-progress v-show="imgFlag21 == true" :percentage="percent21"
								class="webproductapply_percentage"></el-progress>
						</div>
					</el-form-item> -->

					<el-form-item label="联系人" prop="name">
						<div class="webproductapply_inp">
							<el-input v-model="formLabelAlign.name" placeholder='请输入联系人'></el-input>
						</div>
					</el-form-item>

					<el-form-item label="联系电话" prop="phone">
						<div class="webproductapply_inp">
							<el-input v-model.number="formLabelAlign.phone" placeholder='请输入联系电话'></el-input>
						</div>
					</el-form-item>


					<el-form-item label="验证码" prop="code">
						<div class="webproductapply_user_line">
							<el-input v-model="formLabelAlign.code" placeholder='请输入验证码' class='webproductapply_inp1'>
							</el-input>
							<div class="webproductapply_code" @click='$throttle(sendCode)' v-if='flag'>发送验证码</div>
							<div class="webproductapply_code resigter_wait" v-else>{{num}}s 后重新发送</div>
						</div>
					</el-form-item>

					<el-form-item :label="item.file_explain"  v-for="(item,index) in file_type" :key="index"   prop="uploadImg1">
						<div class="webproductapply_form_document">
							
							<div>
								<el-upload ref='uploadfilesdocument' :data="{'index':index}" 
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-exceed='handleExceed' :on-remove="flagArr[index].handleRemove"
								:http-request="httprequest" :on-progress="progress">
								<el-button size="small" type="primary" >选择文件</el-button>
								</el-upload>
							</div>
							
						
							
							<el-progress v-show="flagArr[index].flag == true" :percentage="flagArr[index].p"
								class="webproductapply_percentage"></el-progress>
							<!-- <el-progress v-show="flagArr[index].type == index" :percentage="flagArr[index].p"
								class="webproductapply_percentage"></el-progress> -->
						</div>
					</el-form-item>


				</el-form>

				<div class="webproductapply_line">
					<el-button type="primary" class="webproductapply_shenq" @click='$throttle(onSubmit)'>申请</el-button>
					<el-button type="info" class="webproductapply_info" @click='$throttle(cancelW)'>取消</el-button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import MyBreadcrumb from '@/components/MyBreadcrumb/mybreadcrumb'
	import axios from '../../http'

	export default {
		data() {
			const checkName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('联系电话不能为空'))
				}

				setTimeout(() => {
					if (!Number.isInteger(value)) {
						callback(new Error('请输入数字值'))
					} else {
						if (!this.$regPhone.test(value)) {
							callback(new Error('联系电话格式错误'))
						} else {
							callback()
						}
					}
				}, 500)
			};
			return {
				cityList: [],
				cityListProps: {
					value: "id", //匹配响应数据中的id
					label: "name", //匹配响应数据中的name
					children: "chilren", //匹配响应数据中的children
				},
				documentList:"",
				num: 60,
				flag: true,
				timer: '',
				formLabelAlign: {
					nums: '',
					month: '',
					comname: '',
					address: '',
					name: '',
					phone: '',
					code: '',
					uploadImg1: '',
					uploadImg2: '',
					uploadImg3: '',
					uploadImg4: '',
					uploadImg5: '',
					uploadImg6: '',
					uploadImg7: '',
					uploadImg8: '',
					uploadImg9: '',
					uploadImg10: '',
					uploadImg11: '',
					uploadImg12: '',
					uploadImg13: '',
					uploadImg14: '',
					uploadImg15: '',
					uploadImg16: '',
					uploadImg17: '',
					uploadImg18: '',
					uploadImg19: '',
					uploadImg20: '',
					uploadImg21: ''
				},
				rules: {
					nums: [{
							required: true,
							message: '拟融资额度不能为空'
						},
						{
							type: 'number',
							message: '拟融资额度必须为数字值'
						}
					],
					month: [{
							required: true,
							message: '融资期限不能为空'
						},
						{
							type: 'number',
							message: '融资期限必须为数字值'
						}
					],
					comname: [{
						required: true,
						message: '公司名称不能为空'
					}],
					address: [{
						required: true,
						message: '所在地区不能为空'
					}],
					name: [{
						required: true,
						message: '联系人不能为空'
					}],
					phone: [{
						required: true,
						validator: checkName,
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '验证码不能为空'
					}, ],
					uploadImg1: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg2: [{
						required: true,
						message: '上传资料不能为空'
					}],
				
					uploadImg3: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg4: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg5: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg6: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg7: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg8: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg9: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg10: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg11: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg12: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg13: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg14: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg15: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg16: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg17: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg18: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg19: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg20: [{
						required: true,
						message: '上传资料不能为空'
					}],
					uploadImg21: [{
						required: true,
						message: '上传资料不能为空'
					}]
				},
				file:[],
				flagArr:[],
				imgFlag:false,
				percent:0,
				fileObj:[],
				file_type:[],
				options: [],
				addressId: '',
				documentList1: [],
				percent1: 0,
				imgFlag1: false,
				fileObj1: [{}],
				documentList2: [],
				percent2: 0,
				imgFlag2: false,
				fileObj2: {},
				documentList3: [],
				percent3: 0,
				imgFlag3: false,
				fileObj3: {},
				documentList4: [],
				percent4: 0,
				imgFlag4: false,
				fileObj4: {},
				documentList5: [],
				percent5: 0,
				imgFlag5: false,
				fileObj5: {},
				documentList6: [],
				percent6: 0,
				imgFlag6: false,
				fileObj6: {},
				documentList7: [],
				percent7: 0,
				imgFlag7: false,
				fileObj7: {},
				documentList8: [],
				percent8: 0,
				imgFlag8: false,
				fileObj8: {},
				documentList9: [],
				percent9: 0,
				imgFlag9: false,
				fileObj9: {},
				documentList10: [],
				percent10: 0,
				imgFlag10: false,
				fileObj10: {},
				documentList11: [],
				percent11: 0,
				imgFlag11: false,
				fileObj11: {},
				documentList12: [],
				percent12: 0,
				imgFlag12: false,
				fileObj12: {},
				documentList13: [],
				percent13: 0,
				imgFlag13: false,
				fileObj13: {},
				documentList14: [],
				percent14: 0,
				imgFlag14: false,
				fileObj14: {},
				documentList15: [],
				percent15: 0,
				imgFlag15: false,
				fileObj14: {},
				documentList16: [],
				percent16: 0,
				imgFlag16: false,
				fileObj16: {},
				documentList17: [],
				percent17: 0,
				imgFlag17: false,
				fileObj17: {},
				documentList18: [],
				percent18: 0,
				imgFlag18: false,
				fileObj18: {},
				documentList19: [],
				percent19: 0,
				imgFlag19: false,
				fileObj19: {},
				documentList20: [],
				percent20: 0,
				imgFlag20: false,
				fileObj20: {},
				documentList21: [],
				percent21: 0,
				imgFlag21: false,
				fileObj21: {}
				
			}
		},
		components: {
			'app-mybreadcrumb': MyBreadcrumb
		},
		methods: {
			removeDom(index){
				console.log(index);
				this.flagArr[index].flag = false
				this.flagArr[index].p = 0
				Array.prototype.remove = function(obj) { 
			for (var i = 0; i < this.length; i++) { 
				var temp = this[i]; 
					if (!isNaN(obj)) { 
					temp = i; 
					} 
					if (temp == obj) { 
					for (var j = i; j < this.length; j++) { 
					this[j] = this[j + 1]; 
					} 
					this.length = this.length - 1; 
					} 
					}
			}
				this.fileObj.remove(index)
			},
			getproduct() {
			const id = this.$route.query.ids
				this.$get('/product_file',{id:id}).then(res => {
					this.file_type=res.data.result
					console.log(this.file_type);
					this.file_type.forEach((v,k)=>{
						this.flagArr.push({
							'flag':false,
							'p':0,
							"type":v.type+1,	
							handleRemove:() => {
								let index = k
								console.log(index)
								this.removeDom(k)
							}
						})
					

						// let removeFn = (k) => {
						// 		console.log(44444)
						// }
						// flagArr[k].handleRemove = removeFn(k)
					})
					
				})
			},
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 200;
				if (!isLt2M) {
					this.$message({
						message: '上传文件大小不能超过 200MB!',
						type: 'warning'
					})
				}

				return isLt2M
			},
			handleExceed() {
				this.$message({
					message: '最大上传数量为1张!',
					type: 'warning',
					duration: 1500
				})
			},
			// fn(v){
			// 	this.flagArr[v].p = 0
			// 	this.flagArr[v].flag = false
			// 	this.fileObj[v]=[{}]
			// 	this.handleRemove()
			// },
			handleRemove1(file,fileList) {
							this.uploadImg1 = ''
			},
			handleRemove(){
				this.documentList1 = []
				this.fileObj1 = {}
				this.flagArr.p=0
				this.flagArr.flag=false
				// this.fn()
				// this.flagArr.p = 0
				// this.flagArr.flag = false
				this.fileObj=[


				]
			
				this.imgFlag1 = false
				
			},
			handleRemove2() {
				this.documentList2 = []
				this.fileObj2 = {}
				
				this.percent2 = 0
				this.uploadImg2 = ''
			
				this.imgFlag2 = false
			},
			handleRemove3() {
				this.documentList3 = []
				this.fileObj3 = {}
				
				this.percent3 = 0
				this.uploadImg3 = ''
			
				this.imgFlag3 = false
			},
			handleRemove4() {
				this.documentList4 = []
				this.fileObj4 = {}
				
				this.percent4 = 0
				this.uploadImg4 = ''
			
				this.imgFlag4 = false
			},
			handleRemove5() {
				this.documentList5 = []
				this.fileObj5 = {}
				
				this.percent5 = 0
				this.uploadImg5 = ''
			
				this.imgFlag5 = false
			},
			handleRemove6() {
				this.documentList6 = []
				this.fileObj6 = {}
				
				this.percent6 = 0
				this.uploadImg6 = ''
			
				this.imgFlag6 = false
			},
			handleRemove7() {
				this.documentList7 = []
				this.fileObj7 = {}
				
				this.percent7 = 0
				this.uploadImg7 = ''
			
				this.imgFlag7 = false
			},
			handleRemove8() {
				this.documentList8 = []
				this.fileObj8 = {}
				
				this.percent8 = 0
				this.uploadImg8 = ''
			
				this.imgFlag8 = false
			},
			handleRemove9() {
				this.documentList9 = []
				this.fileObj9 = {}
				
				this.percent9 = 0
				this.uploadImg9 = ''
			
				this.imgFlag9 = false
			},
			handleRemove10() {
				this.documentList10 = []
				this.fileObj10 = {}
				
				this.percent10 = 0
				this.uploadImg10 = ''
			
				this.imgFlag10 = false
			},
			handleRemove11() {
				this.documentList11 = []
				this.fileObj11 = {}
				
				this.percent11 = 0
				this.uploadImg11 = ''
			
				this.imgFlag11 = false
			},
			handleRemove12() {
				this.documentList12 = []
				this.fileObj12 = {}
				
				this.percent12 = 0
				this.uploadImg12 = ''
			
				this.imgFlag12 = false
			},
			handleRemove13() {
				this.documentList13 = []
				this.fileObj13 = {}
				
				this.percent13 = 0
				this.uploadImg13 = ''
			
				this.imgFlag13 = false
			},
			handleRemove14() {
				this.documentList14 = []
				this.fileObj14 = {}
				
				this.percent14 = 0
				this.uploadImg14 = ''
			
				this.imgFlag14 = false
			},
			handleRemove15() {
				this.documentList15 = []
				this.fileObj15 = {}
				
				this.percent15 = 0
				this.uploadImg15 = ''
			
				this.imgFlag15 = false
			},
			handleRemove16() {
				this.documentList16 = []
				this.fileObj16 = {}
				
				this.percent16 = 0
				this.uploadImg16 = ''
			
				this.imgFlag16 = false
			},
			handleRemove17() {
				this.documentList17 = []
				this.fileObj17 = {}
				
				this.percent17 = 0
				this.uploadImg17 = ''
			
				this.imgFlag17 = false
			},
			handleRemove18() {
				this.documentList18 = []
				this.fileObj18 = {}
				
				this.percent18 = 0
				this.uploadImg18 = ''
			
				this.imgFlag18 = false
			},
			handleRemove19() {
				this.documentList19 = []
				this.fileObj19 = {}
				
				this.percent19 = 0
				this.uploadImg19 = ''
			
				this.imgFlag19 = false
			},
			handleRemove20() {
				this.documentList20 = []
				this.fileObj20 = {}
				
				this.percent20 = 0
				this.uploadImg20 = ''
			
				this.imgFlag20 = false
			},
			handleRemove21() {
				this.documentList21 = []
				this.fileObj21 = {}
				
				this.percent21 = 0
				this.uploadImg21 = ''
			
				this.imgFlag21 = false

			},
			progress(){
				console.log(666);
			},
			httprequest(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.flagArr[load.data.index].flag = true
				formdata.append('file', load.file)
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						console.log(percent);
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
						this.flagArr[load.data.index].p = Math.floor(percent) == 100 ? 100 : Math.floor(percent)
						console.log("this.flagArr[load.data.index].p",this.flagArr[load.data.index].p);
			
					}
				}).then((res) => {
					this.flagArr[load.data.index].p = 100
					this.formLabelAlign.uploadImg1 = res.data.address
					console.log(res);
					let fileObjList= {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: this.flagArr[load.data.index].type
					}
					
					this.fileObj.push(fileObjList)
					this.$message.success('文档上传成功！')
					console.log(this.flagArr[0].documentList);
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq2(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag2 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent2 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent2 = 100
					
					this.formLabelAlign.uploadImg2 = res.data.address
					
					this.fileObj2 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 2
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq3(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag3 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent3 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent3 = 100
					
					this.formLabelAlign.uploadImg3 = res.data.address
					
					this.fileObj3 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 3
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq4(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag4 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent4 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent4 = 100
					
					this.formLabelAlign.uploadImg4 = res.data.address
					
					this.fileObj4 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 4
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq5(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag5 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent5 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent5 = 100
					
					this.formLabelAlign.uploadImg5 = res.data.address
					
					this.fileObj5 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 5
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq6(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag6 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent6 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent6 = 100
					
					this.formLabelAlign.uploadImg6 = res.data.address
					
					this.fileObj6 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 6
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq7(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag7 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent7 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent7 = 100
					
					this.formLabelAlign.uploadImg7 = res.data.address
					
					this.fileObj7 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 7
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq8(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag8 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent8 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent8 = 100
					
					this.formLabelAlign.uploadImg8 = res.data.address
					
					this.fileObj8 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 8
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq9(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag9 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent9 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent9 = 100
					
					this.formLabelAlign.uploadImg9 = res.data.address
					
					this.fileObj9 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 9
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq10(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag10 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent10 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent10 = 100
					
					this.formLabelAlign.uploadImg10 = res.data.address
					
					this.fileObj10 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 10
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq11(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag11 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent11 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent11 = 100
					
					this.formLabelAlign.uploadImg1 = res.data.address
					
					this.fileObj11 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 11
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq12(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag12 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent12 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent12 = 100
					
					this.formLabelAlign.uploadImg12 = res.data.address
					
					this.fileObj12 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 12
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq13(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag13 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent13 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent13 = 100
					
					this.formLabelAlign.uploadImg13 = res.data.address
					
					this.fileObj13 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 13
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq14(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag14 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent14 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent14 = 100
					
					this.formLabelAlign.uploadImg14 = res.data.address
					
					this.fileObj14 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 14
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq15(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag15 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent15 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent15 = 100
					
					this.formLabelAlign.uploadImg15 = res.data.address
					
					this.fileObj15 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 15
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq16(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag16 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent16 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent16 = 100
					
					this.formLabelAlign.uploadImg16 = res.data.address
					
					this.fileObj16 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 16
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq17(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag17 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent17 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent17 = 100
					
					this.formLabelAlign.uploadImg17 = res.data.address
					
					this.fileObj17 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 17
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq18(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag18 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent18 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent18 = 100
					
					this.formLabelAlign.uploadImg18 = res.data.address
					
					this.fileObj18 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 18
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq19(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag19 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent19 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent19 = 100
					
					this.formLabelAlign.uploadImg19 = res.data.address
					
					this.fileObj19 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 19
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq20(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag20 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent20 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent20 = 100
					
					this.formLabelAlign.uploadImg20 = res.data.address
					
					this.fileObj20 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 20
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			httpreq21(load) {
				let formdata = new FormData()
				let url = this.baseUrl + '/uploadFile'
				this.imgFlag21 = true
				formdata.append('file', load.file)
			
				axios.post(url, formdata, {
					timeout: 600 * 1000,
					onUploadProgress: progressEvent => {
						let percent = (progressEvent.loaded / progressEvent.total * 100) | 0
						//调用onProgress方法来显示进度条，需要传递个对象 percent为进度值
			
						this.percent21 = Math.floor(percent) == 100 ? 99 : Math.floor(percent)
			
					}
				}).then((res) => {
					this.percent21 = 100
					
					this.formLabelAlign.uploadImg21 = res.data.address
					
					this.fileObj21 = {
						path: res.data.address,
						file_name: res.data.file_name,
						file_size: res.data.file_size,
						name: res.data.name,
						type: 21
					}
			
					this.$message.success('文档上传成功！')
				}).catch((err) => {
					this.$message.error('文档上传超时，请重新上传！')
				})
			},
			// 适用地区
			getQhCityList() {
				let data = {}

				this.$post('/qh_city_lst', data).then(res => {
					this.options = res.data.result ? res.data.result : []
				})
			},
			clearClass() {
				this.addressId = ''
			},
			setAddressId(id) {
				this.addressId = id
			},
			cancelW() {
				this.$router.go(-1)
			},
			onSubmit() {
				// console.log(this.fileObj);
				this.$refs['formLabelAlign'].validate((valid) => {
					if (valid) {
						// let file = [this.fileObj1, this.fileObj2, this.fileObj3, this.fileObj4, this.fileObj5, this.fileObj7, this.fileObj8, this.fileObj9, this.fileObj10, this.fileObj12, this.fileObj13, this.fileObj14, this.fileObj15, this.fileObj16, this.fileObj18, this.fileObj19, this.fileObj20, this.fileObj21]
						
						// if(Object.keys(this.fileObj6).length > 0) {
						// 	file.push(this.fileObj6)
						// }
						
						// if(Object.keys(this.fileObj11).length > 0) {
						// 	file.push(this.fileObj11)
						// }
						
						// if(Object.keys(this.fileObj17).length > 0) {
						// 	file.push(this.fileObj17)
						// }
						let data = {
							quota: this.formLabelAlign.nums,
							term: this.formLabelAlign.month,
							company_name: this.formLabelAlign.comname,
							region_id: this.addressId,
							contacts: this.formLabelAlign.name,
							mobile: this.formLabelAlign.phone,
							token: localStorage.eleToken ? localStorage.eleToken : '',
							product_id: this.$route.query.ids,
							code: this.formLabelAlign.code,
							file:this.fileObj
						}
						
						console.log('data', data)
						this.$post('/ask_add', data).then(res => {
							let type = res.data.status == 1 ? 'success' : 'error'
						
							this.$message({
								message: res.data.msg,
								type,
								duration: 1000
							})
						
							if (res.data.status == 1) {
								setTimeout(() => {
									this.$router.go(-1)
								}, 1500)
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			// 发送验证码
			sendCode() {
				if (this.formLabelAlign.phone == '') {
					this.$message({
						message: '联系电话不能为空',
						type: 'error'
					})
					return false
				}

				if (!this.$regPhone.test(this.formLabelAlign.phone)) {
					this.$message({
						message: '联系电话格式错误',
						type: 'error'
					})
					return false
				}

				let data = {
					phone: this.formLabelAlign.phone
				}

				this.$get('/get_code', data).then(res => {
					console.log(res.data.status, 889)
					if (res.data.status == 1) {
						if (this.timer) {
							clearInterval(this.timer)
						}

						this.flag = false
						this.timer = setInterval(() => {
							this.num--

							if (this.num == 0) {
								clearInterval(this.timer)

								this.flag = true
								this.num = 60
							}
						}, 1000)
					} else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						})
					}
				})

			},
			changeAddress() {
				this.formLabelAlign.address = this.formLabelAlign.address[1]
			}
		},
		beforeRouteEnter(to, from, next) {

			let meta = to.meta
			const courseId = to.query.ids

			to.meta.splice(1, to.meta.length - 1)
			if (from.path == '/productsdetail') {

				meta.push({
					name: '金融产品',
					url: '/financialproducts'
				})
				meta.push({
					name: '产品详情',
					url: `/productsdetail?ids=${courseId}`
				})
				meta.push({
					name: '产品申请',
					url: ''
				})

			}

			if (from.path == '/') {
				meta.push({
					name: '产品申请',
					url: ''
				})
			}

			if (from.path == '/financialproducts') {
				meta.push({
					name: '金融产品',
					url: '/financialproducts'
				})
				meta.push({
					name: '产品申请',
					url: ''
				})
			}

			if (from.path == '/agencydetails') {
				meta.push({
					name: '金融机构',
					url: '/financialinstitutions'
				})
				meta.push({
					name: '金融详情',
					url: `/agencydetails?ids=${courseId}`
				})
				meta.push({
					name: '产品申请',
					url: ''
				})
			}

			next()
		},
		mounted() {

			this.getQhCityList()
			this.getproduct();
		}
	}
</script>

<style scoped>
	@import './webproductapply.css';
</style>
